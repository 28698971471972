import React, { useState } from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {ModalUpdateProduct} from '../produits/ModalUpdateProduct'
import {Link} from 'react-router-dom'
import {ModalDetailsPurchase} from './ModalDetailsPurchase'
import { useAuth } from '../../modules/auth'
import { useCallApi } from '../../modules/utils'
import { toast } from 'react-toastify'
import { Loading } from '../Loading'

type Props = {
  data: any
  returned: boolean
  handleProductCheckboxChange: any
}
export const ItemPurchase: React.FC<Props> = ({returned, data, handleProductCheckboxChange}) => {
    const {hasPermission}=useAuth();
  const callApi=useCallApi();
  const [loading, setLoading] = useState(false)
    const genrateUrPdf=async(id:number)=>{
      setLoading(true)
      const {data} = await callApi({
        route:`api/purchase-pdf-download/${id}`,
        method: 'GET',
      })
      
      if (data && data?.data?.purchase_pdf_url) {
        setLoading(false)
        // Open a new window with the purchase_pdf_url
        window.open(data.data.purchase_pdf_url, '_blank');
    } else {
      setLoading(false)

        console.error('Purchase PDF URL not found in the response data.');
    }
      
    }
  return (
    <>
      {' '}
      <tr>
        <td>
          {' '}
          <div className='form-check form-check-custom form-check-solid mx-5'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={data.checked}
              onChange={handleProductCheckboxChange(data.id)}
            />
          </div>
        </td>
        {/* <td>
          <div className='d-flex align-items-center'>
          

            <div className='d-flex justify-content-start flex-column'>
              <input className='d-none' type='text' value={data.id} />
              <span className=' fw-bold  badge badge-primary  me-auto px-2 py-3 d-block fs-7'>
                #{data.id}
              </span>
            </div>
          </div>
        </td> */}
        <td>
          <div className='d-flex justify-content-start flex-column'>
            <span className='text-dark fw-bold  d-block fs-6'>{data.attributes.notes}</span>
            <div className='card-toolbar ml-3 mt-2'>
              <span
                className={
                  data.attributes.status == 1
                    ? 'badge badge-light-primary me-3'
                    : data.attributes.status == 2
                    ? 'badge badge-light-success me-3'
                    : 'badge badge-light-danger me-3'
                }
              >
                {data.attributes.status == 1
                  ? 'cree '
                  : data.attributes.status == 2
                  ? 'valide'
                  : 'annulee'}
              </span>
              {      hasPermission("manage_purchase") &&  hasPermission("manage_purchase_invoiced")?
               <span
               className={
                 data.attributes.type == 1
                   ? 'badge badge-light-success':
                   'badge badge-light-danger'
                   
               }
             >
               {data.attributes.type == 1
                 ? ' comptabilisée': ' non comptabilisée'}
             </span>:<></> 
}
             
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center text-center flex-column'>
            <span className='text-dark fw-bold  d-block fs-6'>
              {data.attributes?.supplier_name}
            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            {}
            <span className='text-dark fw-bold text-center d-block fs-6'>
              <div className='card-toolbar ml-3'>
                {/* ${dateObj.toISOString().slice(0, 10)} ${dateObj.toLocaleTimeString()} */}
                {new Date(data.attributes.created_at).toISOString().slice(0, 10) +
                  ' ' +
                  new Date(data.attributes.created_at).toLocaleTimeString()}
              </div>
            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            <span className='text-dark fw-bold text-center d-block fs-6'>
              <div className='card-toolbar ml-3'>{data.attributes.grand_total} DH</div>
            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <div className='d-flex justify-content-end flex-shrink-0'>
              <Link
                to={
                  returned
                    ? '/purchases_return_details/' + data.id
                    : '/purchases_details/' + data.id
                }
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                // onClick={()=>deleteProduct(data.id)}
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </Link>

          
              <button
                type='button'
                data-bs-toggle='modal'
                data-bs-target={'#modaldetailspurchase' + data.id}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />{' '}
              </button>
              <ModalDetailsPurchase data={data} />
              {
                loading?<Loading/>:  <button
                type='button'
                title="telecharger facture"
                onClick={()=>genrateUrPdf(data.id)}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-3' />{' '}
              </button>
              }
            

              {/* <a
                              href='#'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              // onClick={()=>deleteProduct(data.id)}
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </a> */}

              {/* <a
                            //   href='#'
                            //   className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            //   // onClick={()=>deleteProduct(data.id)}
                            // >
                            //   <KTSVG
                            //     path='/media/icons/duotune/general/gen027.svg'
                            //     className='svg-icon-3'
                            //   />
                            // </a> */}
            </div>
          </div>
        </td>
        
      </tr>
    </>
  )
}
