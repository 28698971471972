import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {ModalUpdateProduct} from './ModalUpdateProduct'
import {Link} from 'react-router-dom'
import {ProductDetails} from '../../pages/products/ProductDetails'

type Props = {
  data: any
  handleProductCheckboxChange: any
  deleteProduct?: (id: number) => void
  loadProduct:()=>void
}
export const ItemsProducts: React.FC<Props> = ({
  data,
  handleProductCheckboxChange,
  deleteProduct,
  loadProduct
}) => {
  return (
    <>
      {' '}
      <tr>
        <td>
          {' '}
          <div className='form-check form-check-custom form-check-solid mx-5'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={data.checked}
              onChange={handleProductCheckboxChange(data.id)}
            />
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            {/* / */}

            <div className='symbol symbol-45px me-5'>
              <img src={data.attributes.images?.imageUrls?.[0] ?data.attributes.images?.imageUrls?.[0]:"/media/products/productdefault.png"} alt='' />
            </div>
            <div className='d-block justify-content-start flex-column'>
              <input className='d-none' type='text' value={data.id} />
              <span className='text-dark fw-bold  d-block fs-6'>{data.attributes.name}</span>
              {data.attributes?.promotions ? (
                <span className={' badge badge-light-info fw-bolder me-auto px-2 py-3'}>
                  promo :{data.attributes?.promotion_value} %
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-start flex-column'>
            <span className='text-dark fw-bold  d-block fs-6'>
              {data.attributes.product_category_name}
            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center text-center flex-column'>
            <span className='text-dark fw-bold d-block fs-6'>
              {data.attributes?.promotions ? (
                <>
                  <span style={{color: 'red'}}>
                    <s>{data.attributes?.product_price + ' MAD'}</s>{' '}
                    {/* Display original price with strikethrough */}
                  </span>{' '}
                  {data.attributes?.product_price -
                    data.attributes?.product_price * (data.attributes?.promotion_value / 100) +
                    ' MAD'}
                </>
              ) : (
                data.attributes?.product_price + ' MAD'
              )}
            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            {}
            <span className='text-dark fw-bold text-center d-block fs-6'>
              <div className='card-toolbar ml-3'>
                <span
                  className={
                    data.attributes.status == 1
                      ? ' badge badge-light-success'
                      : ' badge badge-light  ' + 'fw-bolder me-auto px-2 py-3'
                  }
                >
                  {data.attributes.status == 1 ? 'active' : 'desactive'}
                </span>
              </div>
            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                type='button'
                data-bs-toggle='modal'
                data-bs-target={'#modalupdateproduct' + data.id}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </button>
              <ModalUpdateProduct loadProduct={loadProduct} data={data} />
              {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>

<div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        ...
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>   */}

              <Link
                to={{
                  pathname: '/products/details/' + data.id,
                  // search: `?product_price=${data.attributes.product_price}&price_purchase_ttc=${data.attributes.price_purchase}`,
                }}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
              </Link>

              {/* <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      // onClick={()=>deleteProduct(data.id)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a> */}
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}
