import React, { useEffect, useState } from 'react'
import { ListPurchase } from '../../components/purchase/ListPurchase'
import { Pagination } from '@mui/material';
import { ToastContainer } from 'react-toastify';

type Props={
  returned:boolean
}
export const Purchase:React.FC<Props> = ({returned}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  const [totalItems, setTotalItems] = useState(0);
  console.log({totalPages,currentPage,totalItems});
  function handlePageChange(event:any, value:any) {
    setCurrentPage(value);
    

  }

  
  

  
  return (
    <>
            <ListPurchase states={{currentPage,setCurrentPage,totalItems,setTotalItems,setTotalPages,returned}}/>
            {/* <Pagination count={20} page={10} color="primary"  onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
            <Pagination count={totalPages} page={currentPage} color="primary"  onChange={handlePageChange}  className="d-flex justify-content-center"/>
    </>
  )
}
