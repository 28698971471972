import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import JournalDeVente from '../pages/Ventes/JournalDeVente'
import {Products} from '../pages/products/Products'
import {ProductDetails} from '../pages/products/ProductDetails'
import {Purchase} from '../pages/achat/Purchase'
import {DetailsPurchase} from '../pages/achat/DetailsPurchase'
import {NewPurchase} from '../components/purchase/NewPurchase'
import {ListStock} from '../components/sysStock/ListStock'
import {Stock} from '../pages/Stock/Stock'
import {ListVente} from '../pages/journal_vente/ListVente'
import {ListSubSale} from '../components/journalVente/ListSubSale'
import {DetailsFourniosseur} from '../components/fournisseur/detailsFournisseur'
import {Fournisseur} from '../pages/fournisseur/Fournisseur'
import {Utilisateur} from '../pages/utilisateurs/Utilisateur'
import {Categorie} from '../pages/categorie/categorie'
import {Charges} from '../pages/charges/charges'
import {DetailsCharges} from '../pages/charges/detailsCharges'
import { Promotions } from '../pages/promotion/Promotions'
import ListClient from '../pages/Clients/ListClient'
import { useAuth } from '../modules/auth'
import { AddSale } from '../pages/Ventes/AddSale'
import { ListSaleInvoiced } from '../pages/Ventes/ListSaleInvoiced'
import { DetailsSale } from '../pages/Ventes/DetailsSale'

const PrivateRoutes = () => {
  const {hasPermission}=useAuth()
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={  hasPermission('manage_dashboard') ? '/dashboard':'/ventes/journal-de-vente'} />} />
        {/* Pages */}
        <Route path='dashboard' element={
           hasPermission('manage_dashboard') ? (
            <DashboardWrapper />
            ) : (
            <Navigate to="/ventes/journal-de-vente" />
          )
        
        } />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='products'
          element={
            hasPermission('manage_products') ? (
              <Products byDashboard={false} />
            ) : (
              <Navigate to="/ventes/journal-de-vente" />
            )
            // <SuspensedView>
            // </SuspensedView>
          }
        />
         <Route
          path='promotions'
          element={
            hasPermission('manage_promotion') ? (
            // <SuspensedView>
            <Promotions />
            ) : (
              <Navigate to="ventes/journal-de-vente" />
            )
            // </SuspensedView>
          }
        />
          <Route
          path='/add_sale'
          element={
            hasPermission('manage_sale') ? (
            // <SuspensedView>
            <AddSale />
            ) : (
              <Navigate to="ventes/journal-de-vente" />
            )
            // </SuspensedView>
          }
        />
          <Route
          path='/list_sales_invoiced'
          element={
            hasPermission('manage_sale') ? (
            // <SuspensedView>
            <ListSaleInvoiced  />
            ) : (
              <Navigate to="ventes/journal-de-vente" />
            )
            // </SuspensedView>
          }
        />
         <Route
          path='/sale/:id'
          element={
            hasPermission('manage_sale') ? (
            // <SuspensedView>
            <DetailsSale returned={false}  />
            ) : (
              <Navigate to="ventes/journal-de-vente" />
            )
            // </SuspensedView>
          }
        />
           <Route
          path='clients'
          element={
            hasPermission('manage_customers') ? (
              // <SuspensedView>
              <ListClient />
              ) : (
                <Navigate to="ventes/journal-de-vente" />
              )
            // <SuspensedView>
      
            // </SuspensedView>
          }
        />
        
        <Route
          path='purchases'
          element={
            hasPermission('manage_purchase') || hasPermission('manage_purchase_invoiced')? (
              // <SuspensedView>
              <Purchase  returned={false}/>

              ) : (
                <Navigate to="/ventes/journal-de-vente" />
              )
            // <SuspensedView>
            // </SuspensedView>
          }
        />
           <Route
          path='purchases-returned'
          element={
            hasPermission('manage_purchase') || hasPermission('manage_purchase_invoiced')? (
              // <SuspensedView>
              <Purchase returned={true}/>

              ) : (
                <Navigate to="/ventes/journal-de-vente" />
              )
            // <SuspensedView>
            // </SuspensedView>
          }
        />
        <Route
          path='Utilisateur'
          element={
            hasPermission('manage_users') ? (
              // <SuspensedView>
              <Utilisateur />

              ) : (
                <Navigate to="/ventes/journal-de-vente" />
              )
            // <SuspensedView>
           
            // </SuspensedView>
          }
        />
        <Route
          path='categorie'
          element={
            hasPermission('manage_product_categories') ? (
              // <SuspensedView>
              <Categorie />
              ) : (
                <Navigate to="/ventes/journal-de-vente" />
              )
            // <SuspensedView>
           
            // </SuspensedView>
          }
        />
        <Route
          path='charges'
          element={
            hasPermission('manage_expenses') ? (
              // <SuspensedView>
               <Charges />
              ) : (
                <Navigate to="/ventes/journal-de-vente" />
              )
            // <SuspensedView>
           
            // </SuspensedView>
          }
        />
        <Route
          path='detailes-charge'
          element={
            hasPermission('manage_expenses') ? (
              // <SuspensedView>
               <Charges />
              ) : (
                <Navigate to="/ventes/journal-de-vente" />
              )
            // <SuspensedView>
           
            // </SuspensedView>
          }
        />
        <Route
          path='fournisseur'
          element={
            hasPermission('manage_suppliers') ? (
              // <SuspensedView>
              <Fournisseur />              ) : (
                <Navigate to="/ventes/journal-de-vente" />
              )
            // <SuspensedView>
       
            // </SuspensedView>
          }
        />
        <Route
          path='fournisseur/details/:id'
          element={
            hasPermission('manage_suppliers') ? (
              // <SuspensedView>
              <DetailsFourniosseur />
              ) : (
                <Navigate to="/ventes/journal-de-vente" />
              )
            // <SuspensedView>
            // </SuspensedView>
          }
        />
        <Route
          path='purchase/add'
          element={
            hasPermission('manage_purchase') ? (
              // <SuspensedView>
              <NewPurchase />

              ) : (
                <Navigate to="/ventes/journal-de-vente" />
              )
            // <SuspensedView>
            // </SuspensedView>
          }
        />
        <Route
          path='purchases_details/:id'
          element={
            hasPermission('manage_purchase') || hasPermission('manage_purchase_invoiced')  ? (
              // <SuspensedView>
              <DetailsPurchase  returned={false}  />

              ) : (
                <Navigate to="/ventes/journal-de-vente" />
              )
            // <SuspensedView>
           
            // </SuspensedView>
          }
        />
         <Route
          path='purchases_return_details/:id'
          element={
            hasPermission('manage_purchase') || hasPermission('manage_purchase_invoiced')  ? (
              // <SuspensedView>
              <DetailsPurchase returned={true} />

              ) : (
                <Navigate to="/ventes/journal-de-vente" />
              )
            // <SuspensedView>
           
            // </SuspensedView>
          }
        />

        <Route
          path='products/details/:id'
          element={
            hasPermission('manage_products') ? (
              // <SuspensedView>
              <ProductDetails />


              ) : (
                <Navigate to="/ventes/journal-de-vente" />
              )
            // <SuspensedView>
            // </SuspensedView>
          }
        />
        <Route
          path='stock'
          element={
            hasPermission('manage_purchase') || hasPermission('manage_purchase_invoiced') ? (
              // <SuspensedView>
              <Stock />


              ) : (
                <Navigate to="/ventes/journal-de-vente" />
              )
            // <SuspensedView>
            // </SuspensedView>
          }
        />
        <Route
          path='ventes/journal-de-vente'
          element={
            // <SuspensedView>
            <ListVente />
            // </SuspensedView>
          }
        />
        <Route
          path='/journal-ventes/details'
          element={
            // <SuspensedView>
            <ListSubSale bySale={true} />
            // </SuspensedView>
          }
        />
        <Route
          path='/journal-ventes/commande'
          element={
            // <SuspensedView>
            <ListSubSale bySale={false} />
            // </SuspensedView>
          }
        />
         <Route
          path='/commande'
          element={
            // <SuspensedView>
            <ListSubSale bySale={"search"} />
            // </SuspensedView>
          }
        />
        
        <Route
          path='/commandes'
          element={
            // <SuspensedView>
            <ListSubSale bySale={"search"} />
            // </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-manage/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
